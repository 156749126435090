import React from "react";

var CryptoJS = require("crypto-js");

export const encryptDecrypt = {
    /**
     * decrypt plain encrypted string data
    */
    decryptStringHelper,
    /**
     * decrypt json object encrypted data to json object @return {JSON Object}
    */
    decryptObjectHelper,
    /**
        * encrypt plain string data @return {plainText encrypted data}
       */
    encryptStringHelper,
    /**
    * encrypt json object data @return {plainText encrypted data}
    */
    encryptObjectHelper,
    encpwd,
    decpwd,
    EncryptText
      /**
    * encrypt json object data @return {plainText encrypted data}
    */

}


async function decryptStringHelper(cipherText) {
    let decryptedData = ''
    // if (cipherText !== null && cipherText !== '') {
    //     let bytes = CryptoJS.AES.decrypt(cipherText, process.env.REACT_APP_encryp_Key);
    //     decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    // }
    if (cipherText !== '') {
        const key2 = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_encryp_Key);
        const iv2 = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_encrypt_Iv);
        const dec = CryptoJS.AES.decrypt(cipherText, key2, {
            iv: iv2,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
            keySize: 256 / 32
        });
        decryptedData = dec.toString(CryptoJS.enc.Utf8);
    }
    return decryptedData;

}

function decryptObjectHelper(cipherText) {
    
    let decryptedData = {};
    // if (cipherText !== null && cipherText !== '') {
    //     let bytes = CryptoJS.AES.decrypt(cipherText, process.env.REACT_APP_encryp_Key);
    //     decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    // }
    if (cipherText !== '') {
        const key2 = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_encryp_Key);
        const iv2 = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_encrypt_Iv);
        const dec = CryptoJS.AES.decrypt(cipherText, key2, {
            iv: iv2,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
            keySize: 256 / 32
        });
        return JSON.parse(dec.toString(CryptoJS.enc.Utf8));
        
    }
    return decryptedData;
}

function encryptStringHelper(cipherText) {
    let encryptedData = ''
    const key2 = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_encryp_Key);
    const iv2 = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_encrypt_Iv);
    if (cipherText !== null && cipherText !== '') {
        encryptedData = CryptoJS.AES.encrypt(cipherText, key2,
            {
                iv: iv2,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
                keySize: 256 / 32
            }
        ).toString();
    }
    return encryptedData;

}

function encryptObjectHelper(jsonData) {
    let encryptedObject = ''
    const key2 = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_encryp_Key);
    const iv2 = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_encrypt_Iv);
    if (jsonData !== null && jsonData !== '' && typeof jsonData === 'object') {
        let jsonString = JSON.stringify(jsonData);
        encryptedObject = CryptoJS.AES.encrypt(jsonString, key2,
            {
                iv: iv2,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
                keySize: 256 / 32
            }
        ).toString();
    }
    if(encryptedObject === '')
        {
            encryptedObject = jsonData;
        }
    return encryptedObject;
}

function encpwd(cipherText) {
    let encryptedString = ''
    if (cipherText !== '') {
        /*
        Length of secret key should be 16 digits for 128 bits key size like CryptoJS.enc.Utf8.parse('8080808080808080')
        Length of secret key should be 24 digits for 192 bits key size like CryptoJS.enc.Utf8.parse('808080808080808012345678')
        Length of secret key should be 32 digits for 256 bits key size like  CryptoJS.enc.Utf8.parse('12345678901234567890123456789012');
        keySize: 128 / 8
        keySize: 256 / 32
        keySize: 192 / 24
        */
        const key2 = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_encryp_Key);
        const iv2 = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_encrypt_Iv);
       
        encryptedString = CryptoJS.AES.encrypt(cipherText, key2, {
            iv: iv2,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
            keySize: 256 / 32
        });
    }

    return encryptedString.toString();
}
function decpwd(cipherText) {
    let decryptedString = ''

    if (cipherText !== '') {
        const key2 = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_encryp_Key);
        const iv2 = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_encrypt_Iv);
        const dec = CryptoJS.AES.decrypt(cipherText, key2, {
            iv: iv2,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
            keySize: 256 / 32
        });
        decryptedString = dec.toString(CryptoJS.enc.Utf8);
    }
    return decryptedString;
}

    function EncryptText(text,key1)
    {
        key1 = key1.replace(/\-/g,"");
        var text = CryptoJS.enc.Utf8.parse(text);
        var key = CryptoJS.enc.Utf8.parse(key1); //secret key
        
        var IV = CryptoJS.enc.Utf8.parse(key1.substring(0, 16)); //16 digit
        var encryptedString = CryptoJS.AES.encrypt(text, key, {
            iv: IV,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
            keySize: 256 / 32
        });

        return encryptedString.toString();
    }
